<template>
  <base-dialog-form
    :dialog="dialog"
    :loading="loading"
    :title="`${this.referenceIndex ? 'Edit' : 'Create'} reference index`"
    actionText="Save coefficient details"
    @close="$emit('close')"
    @submit="submit()"
  >
    <template #content>
      <v-autocomplete
        dense
        outlined
        no-filter
        hide-selected
        persistent-hint
        label="Farm resource *"
        class="rounded-lg"
        item-text="name"
        item-value="resource_uid"
        :items="farmResources.data"
        :search-input.sync="resourceSearch"
        v-model="referenceIndexObj.resource_uid"
        :hint="errors.get('resource_uid')"
        :error="errors.has('resource_uid')"
        @input="errors.clear('resource_uid')"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title class="body-2">
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action class="caption">
            <v-icon small>
              mdi-{{ item.category.name === 'livestock' ? 'cow' :  'sprout' }}
            </v-icon>
          </v-list-item-action>
        </template>
      </v-autocomplete>

      <v-select
        dense
        outlined
        persistent-hint
        class="rounded-lg"
        label="Reference unit *"
        :items="referenceUnits.data"
        item-text="name"
        item-value="reference_unit_uid"
        v-model="referenceIndexObj.reference_unit_uid"
        :hint="errors.get('reference_unit_uid')"
        :error="errors.has('reference_unit_uid')"
        @input="errors.clear('reference_unit_uid')"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title class="body-2">
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.product.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-select>

      <v-text-field
        dense
        outlined
        persistent-hint
        class="rounded-lg"
        type="number"
        step=".01"
        label="Index value *"
        v-model="referenceIndexObj.value"
        :hint="errors.get('value')"
        :error="errors.has('value')"
        @input="errors.clear('value')"
      ></v-text-field>
    </template>
  </base-dialog-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ReferenceIndex from '@/libs/insurance/ReferenceIndex'

export default {
  props: {
    dialog: {
      required: true,
      type: Boolean,
    },

    referenceIndex: {
      type: Object
    }
  },

  data () {
    return {
      loading: false,
      resourceSearch: '',
      referenceIndexObj: new ReferenceIndex(),
    }
  },

  watch: {
  //   action (value) {
  //     this.dialog = Boolean(value)
  //   },

    resourceSearch (n, o) {
      if (n && n.length > 2) {
        this.searchResources(n)
      }

      if (!n && o.length) {
        this.loadFarmResources()
      }
    },

  //   // product (product) {
  //   //   if (product) {
  //   //     Object.keys(product).forEach(key => {
  //   //       if (this.productObj.hasOwnProperty(key)) {
  //   //         this.productObj[key] = product[key]
  //   //       }
  //   //     })
  //   //   }
  //   // },
  },

  computed: {
    ...mapGetters({
      farmResources: 'getFarmResources',
      referenceUnits: 'getReferenceUnits',
    }),

    errors () {
      return this.referenceIndexObj.form.errors
    },
  },

  methods: {
    ...mapActions([
      'setFarmResources',
      'setReferenceUnits',
      'setReferenceIndices'
    ]),

    loadFarmResources (search = '') {
      this.setFarmResources({
        params: {
          ...(search && { search })
        }
      })
    },

    searchResources: _.debounce(function(search) {
      this.loadFarmResources(search)
    }, 500),

    submit () {
      if (!this.loading) {
        this.loading = true
        !this.jurisdiction ? this.store() : this.update()
      }
    },

    store () {
      this.referenceIndexObj.store().then(() => {
        this.setReferenceIndices().then(() => {
          this.$emit('close')
        })
      }).finally(() => {
        this.loading = false
      })
    },

  //   update () {
  //     this.referenceUnitCoefficientObj
  //     .update(this.referenceUnitCoefficient.reference_unit_coefficient_uid)
  //       .then(() => {
  //         this.setReferenceUnitCoefficients().then(() => {
  //           this.$emit('close')
  //         })
  //       }).finally(() => {
  //         this.loading = false
  //       })
  //   },
  },

  mounted () {
    this.setReferenceUnits()
      .then(() => this.loadFarmResources())
  }
}
</script>